// tailwindcss
import { loadableReady } from '@loadable/component';
import ReactDOM from 'react-dom';
import './src/styles/global.css';

export const replaceHydrateFunction = () => {
  return (element, container, callback) => {
    loadableReady(() => {
      ReactDOM.render(element, container, callback);
    });
  };
};

// Updating Gatsby's default scroll behavior
export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  if (location.pathname === '/') {
    window.scrollTo(0, 0);
    return false; // false to prevent Gatsby's default scroll behavior
  }

  const savedPosition = getSavedScrollPosition(location);
  window.scrollTo(...(savedPosition || [0, 0]));

  return false; // false to prevent Gatsby's default scroll behavior
};

async function handleHardReload(url) {
  await fetch(url, {
    headers: {
      Pragma: 'no-cache',
      Expires: '-1',
      'Cache-Control': 'no-cache',
    },
  });
  window.location.href = url;
  // This is to ensure reload with url's having '#'
  window.location.reload();
}

export const onServiceWorkerUpdateReady = () => {
  handleHardReload(window.location.href);
};
